<template>
    <div>
        <el-form :model="formData"
                 :rules="formRules"
                 ref="form"
                 size="mini"
                 :disabled="type === 'detail'">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'组件'"
                                  prop="component">
                        <el-input v-model="formData.component"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'小部件中文名'"
                                  prop="name">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'小部件示例描述'"
                                  prop="description">
                        <el-input v-model="formData.description" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'小部件英文名'"
                                  prop="englishtName">
                        <el-input v-model="formData.englishtName"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'小部件英文描述'"
                                  prop="englishDescription">
                        <el-input v-model="formData.englishDescription" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'默认宽'"
                                  prop="width">
                        <el-input-number v-model="formData.width"
                                         size="small"
                                         style="width:100%"
                                         :min="0"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'默认高'"
                                  prop="height">
                        <el-input-number v-model="formData.height"
                                         size="small"
                                         style="width:100%"
                                         :min="0"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'NationalInfoForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            formData: {
                component: '',
                name: '',
                description: '',
                englishtName: '',
                englishDescription: '',
                height: '',
                id: this.id,
                width: '',
            },
            formRules: {
                component: [
                    {
                        required: true,
                        message: '请输入组件',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '请输入小部件中文名',
                        trigger: 'blur'
                    }
                ],
                description: [
                    {
                        required: true,
                        message: '请输入小部件示例描述',
                        trigger: 'blur'
                    }
                ],
                englishtName: [
                    {
                        required: true,
                        message: '请输入小部件英文名',
                        trigger: 'blur'
                    }
                ],
                englishDescription: [
                    {
                        required: true,
                        message: '请输入小部件英文描述',
                        trigger: 'blur'
                    }
                ],
                height: [
                    {
                        required: false,
                        message: '请输入默认高',
                        trigger: 'blur'
                    }
                ],
                width: [
                    {
                        required: false,
                        message: '请输入默认宽',
                        trigger: 'blur'
                    }
                ]
            }
        };
    },

    methods: {
        async initFormValue() {
            const {type, id} = this;
            //编辑
            if ((type === 'edit' || type === 'detail') && id != null) {
                this.getNationalInfo(this.id);
            }
        },
        getNationalInfo(id) {
            this.$client.getWidgetInfo(id).then(data => {
                this.formData = data || {};
            });
        },
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (this.type === 'edit') {
                    if (valid) {
                        this.$client.updateWidgetInfo(this.formData).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                    }
                } else if (valid) {
                    const tmp = {...this.formData};
                    delete tmp.id;
                    this.$client.addWidgetInfo(tmp).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
</style>

