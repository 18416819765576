<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions">
            <el-button type="primary" size="mini" @click="addHandle">{{'新建'}}</el-button>
            <el-button type="primary" size="mini" @click="editHandle">{{'编辑'}}</el-button>
            <el-button type="primary" size="mini" @click="showDetail">{{'详情'}}</el-button>
            <el-button type="primary" size="mini" @click="deleteHandle">{{'删除'}}</el-button>
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getWidgetInfos"
                :columns="columns"
                :formOptions="formOptions"
                @selection-change="selectHandle"
            ></ex-search-table-pagination>
        </div>
        <el-dialog
            width="700px"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <component-management-info-form ref="editForm" :id="dialog.currentId" :type="dialog.type"></component-management-info-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">
                    {{dialog.type === 'detail' ? '关闭' : '取消'}}
                </el-button>
                <el-button
                    type="primary"
                    @click="submitHandle"
                    size="mini"
                    v-if="dialog.type !== 'detail'"
                >{{'确定'}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import ComponentManagementInfoForm from './ComponentManagementInfoForm';
export default {
    name: 'ComponentManagementInfo',
    components: {
        ComponentManagementInfoForm
    },
    data() {
        return {
            columns: [
                {
                    type: 'selection'
                },
                {
                    prop: 'component',
                    label: '组件'
                },
                {
                    prop: 'name',
                    label: '小部件中文名'
                },
                {
                    prop: 'description',
                    label: '小部件示例描述'
                },
                {
                    prop: 'englishtName',
                    label: '小部件英文名'
                },
                {
                    prop: 'englishDescription',
                    label: '小部件英文描述'
                },
                {
                    prop: 'width',
                    label: '默认宽',
                    width: 100
                },
                {
                    prop: 'height',
                    label: '默认高',
                    width: 100
                }
            ],
            formOptions: {
                forms: [
                    {
                        prop: 'name',
                        label: '小部件中文名',
                        placeholder: '请输入小部件中文名',
                    },
                    {
                        prop: 'englishtName',
                        label: '小部件英文名',
                        placeholder: '请输入小部件英文名',
                    }
                ]
            },
            searchRules: {},
            selectedRows: [],
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                tyle: ''
            }
        };
    },
    methods: {
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        addHandle() {
            this.dialog = {
                title: '新建',
                currentId: undefined,
                visible: true,
                type: 'add'
            };
        },
        editHandle() {
            if (this.handleValidate('edit')) {
                this.dialog = {
                    currentId: this.selectedRows[0].id,
                    title: '编辑',
                    visible: true,
                    type: 'edit'
                };
            }
        },
        deleteHandle() {
            if (this.handleValidate('delete')) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning'
                }).then(() => {
                    const {selectedRows} = this;
                    const ids = selectedRows.map(item => item.id);
                    this.$client
                        .deleteWidget(ids)
                        .then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.$refs.searchTable.searchHandler();
                        })
                        .catch(() => {
                            this.$message({
                                message: '操作失败！',
                                type: 'error'
                            });
                        });
                });
            }
        },
        showDetail() {
            if (this.handleValidate('detail')) {
                this.dialog = {
                    currentId: this.selectedRows[0].id,
                    title: '详情',
                    visible: true,
                    type: 'detail'
                };
            }
        },
        handleValidate(type) {
            const {selectedRows} = this;
            const length = selectedRows.length;
            if (length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
                return false;
            }
            if ((type === 'edit' || type === 'detail') && length > 1) {
                this.$message({
                    message: '只允许对一条进行操作',
                    type: 'warning'
                });
                return false;
            }
            return true;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(rest => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        }
    }
};
</script>
